.fade-in {
  animation: fadeIn 4s;
  opacity: 1;
}

.fade-out {
  animation: fadeOut 4s;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.stepper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepOuter{
   width: 38px;
  height: 38px;
  background-color: #FFFFFF;
  color: #1678F2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0;

}

.step {
  width: 38px;
  height: 38px;
  background-color: white;
  color:#1678F2;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: background-color 3s, height 3s;
  /* opacity: 0.5; */
}

.step.active,
.step.visited {
  width: 38px;
  height: 38px;
  background-color: #1678F2;
  color: #fff;
  opacity: 1;
}

.ProgressOuter {
  width: 4px;
  height: 26px;
  background-color: #FFFFFF;
}
.ProgressInner{
  width: 4px;
  background-color: #1678F2;
  height: 0px;
  -webkit-transition: height 1s; 
  transition: height 1s;
}
.ProgressInner.activeProgressInner,
.ProgressInner.visitedProgress{
  height: 26px;
}
/* .ProgressOuter :hover{
} */

.step-content {
  margin-left: 20px;
}

@media (max-width: 980px) {
  .stepper {
    flex-direction: row;
    justify-content: center;
  }
  .ProgressOuter {
    width: 40px;
    height: 4px;
    /* background-color: #D9EBFF; */
    transition: background-color 1s;
  }
  .ProgressInner{
  width: 0px;
  background-color: #1678F2;
  height: 4px;
  -webkit-transition: width 5s; 
  transition: width 5s;
}
.ProgressInner.activeProgressInner,
.ProgressInner.visitedProgress{
  width: 40px;
  height:4px;
}
}
@media (max-width: 500px) {
  .ProgressOuter {
    width: 55px;
  }
}
@media (max-width: 350px) {
  .ProgressOuter {
    width: 32px;
  }
}

.featureSectionCss{
  opacity: 1;
  transition: opacity 0.9s ease-in-out;
}
